export const CONSTANTE_TIPO_VEICULO = [
    {fullImage: "imagens/iconesCarros/iconeCARRO90.png", label:"Carro", data:"CARRO"},
    {fullImage: "imagens/iconesCarros/iconeMOTO90.png", label:"Moto", data:"MOTO"},    
    {fullImage: "imagens/iconesCarros/iconeCAMINHAOA90.png",label:"Caminhão Toco", data:"CAMINHAOA"},
    {fullImage: "imagens/iconesCarros/iconeCAMINHAOBAU90.png",label:"Caminhão Baú Trucado", data:"CAMINHAOBAU"},
    {fullImage: "imagens/iconesCarros/iconeCAMINHAO2BAU90.png",label:"Caminhão Baú", data:"CAMINHAO2BAU"},
    {fullImage: "imagens/iconesCarros/iconeCAMINHAOBAUGRANDE90.png",label:"Caminhão Baú Grande", data:"CAMINHAOBAUGRANDE"},
    {fullImage: "imagens/iconesCarros/iconeCAMINHAOC90.png",label:"Caminhão Tipo 2", data:"CAMINHAOC"},
    {fullImage: "imagens/iconesCarros/iconeCARROC90.png",label:"Carro Furgão", data:"CARROC"},
    {fullImage: "imagens/iconesCarros/iconeONIBUS90.png",label:"Onibus", data:"ONIBUS"},
    {fullImage: "imagens/iconesCarros/iconePICKUP90.png",label:"Pick Up", data:"PICKUP"},
    {fullImage: "imagens/iconesCarros/iconeVAN90.png",label:"Van", data:"VAN"},
    {fullImage: "imagens/iconesCarros/iconePORTATIL90.png",label:"Portátil", data:"PORTATIL"},
    {fullImage: "imagens/iconesCarros/iconeRETROESCAVADEIRA90.png",label:"Retroescavadeira", data:"RETROESCAVADEIRA"},
    {fullImage: "imagens/iconesCarros/iconeROLOCOMPRESSOR90.png",label:"Rolocompressor", data:"ROLOCOMPRESSOR"},
    {fullImage: "imagens/iconesCarros/iconeGUINCHO90.png",label:"Guincho", data:"GUINCHO"},
    {fullImage: "imagens/iconesCarros/iconeLANCHA90.png",label:"Lancha", data:"LANCHA"},
    {fullImage: "imagens/iconesCarros/iconeCARRETA90.png",label:"Carreta", data:"CARRETA"}, 
    {fullImage: "imagens/iconesCarros/iconePLATAFORMA90.png",label:"Plataforma", data:"PLATAFORMA"},
    {fullImage: "imagens/iconesCarros/iconeVEICULO190.png",label:"Veiculo1", data:"VEICULO1"},
    {fullImage: "imagens/iconesCarros/iconeVEICULO290.png",label:"Veiculo2", data:"VEICULO2"},
    {fullImage: "imagens/iconesCarros/iconeVEICULO390.png",label:"Veiculo3", data:"VEICULO3"},
    {fullImage: "imagens/iconesCarros/iconeVEICULO490.png",label:"Veiculo4", data:"VEICULO4"},
    {fullImage: "imagens/iconesCarros/iconeVEICULO590.png",label:"Veiculo5", data:"VEICULO5"},
    {fullImage: "imagens/iconesCarros/iconeVEICULO690.png",label:"Veiculo6", data:"VEICULO6"},
    {fullImage: "imagens/iconesCarros/iconeVEICULO790.png",label:"Veiculo7", data:"VEICULO7"},
    {fullImage: "imagens/iconesCarros/iconeVEICULO890.png",label:"Veiculo8", data:"VEICULO8"},
    {fullImage: "imagens/iconesCarros/iconeVEICULO990.png",label:"Veiculo9", data:"VEICULO9"},
    {fullImage: "imagens/iconesCarros/iconeGERADOR90.png",label:"Gerador", data:"GERADOR"},
    {fullImage: "imagens/iconesCarros/iconeMODEM90.png",label:"Modem", data:"MODEM"},
    {fullImage: "imagens/iconesCarros/iconeBATERIA90.png",label:"Bateria", data:"BATERIA"},
    {fullImage: "imagens/iconesCarros/iconeTANQUE90.png",label:"Tanque", data:"TANQUE"},
    {fullImage: "imagens/iconesCarros/iconeTRATORESTEIRA90.png",label:"Trator Esteira", data:"TRATORESTEIRA"},
    {fullImage: "imagens/iconesCarros/iconeCAMINHAOBITRUCK90.png",label:"Bitruck", data:"CAMINHAOBITRUCK"},
    {fullImage: "imagens/iconesCarros/iconeAVIAO90.png",label:"Avião", data:"AVIAO"},
    {fullImage: "imagens/iconesCarros/iconeHELICOPTERO90.png",label:"Helicoptero", data:"HELICOPTERO"},
    {fullImage: "imagens/iconesCarros/iconeCARRETA290.png",label:"Cavalo Carreta", data:"CARRETA2"},
    {fullImage: "imagens/iconesCarros/iconeBOMBA90.png",label:"Bomba", data:"BOMBA"},
    {fullImage: "imagens/iconesCarros/iconePAVIMENTADORA90.png",label:"Pavimentadora", data:"PAVIMENTADORA"}];